<template>
  <div>
    <b-overlay :show="loading">
      <filtros-descuentos
        report-type="descuento"
        @buscar="buscar"
      />
      <b-card>
        <b-card-body>
          <h4 class="card-title">
            Lista Descuento Productos
          </h4>
          <div
            v-show="verTablaListado"
            id="tablaListado"
          >
            <div>
              <b-row>
                {{ notificaciones }}
                <b-col sm="12" md="6"  class="my-1">
                  <div class="d-flex align-items-center mb-1 mb-md-0">
                    <label>Mostrar</label>
                    <b-form-select
                            @change="onPerPageChange"
                            id="perPageSelect"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                            class="mx-50 col-md-2"
                    ></b-form-select>
                    <label>filas por página</label>
                  </div>
                </b-col>
                <b-col md="6">
                  <div class="d-flex justify-content-end text-right">
                  <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-modal.modal-crear
                          variant="outline-primary"
                          @click="limpiarFormCrear()"
                  >
                    <feather-icon
                                  icon="FilePlusIcon"
                                  class="mr-50"
                    />
                    Crear Descuento
                  </b-button>
                  </div>
                </b-col>

                <b-col cols="12">
                  <b-table
                      striped
                      hover
                      small
                      :per-page="perPage"
                      :items="items"
                      :fields="fields"
                      responsive
                      class="mb-0"
                      show-empty
                  >
                    <template #cell(tipo_producto)="data">
                      {{ data.item.tipo_producto.nombre }}
                    </template>
                    <template #cell(modalidad)="data">
                      {{ data.item.modalidad.name }}
                    </template>
                    <template #cell(tipo_descuento)="data">
                      {{ data.item.tipo_descuento.nombre }}
                    </template>
                    <template #cell(activo)="data">
                      <b-badge :variant="activo[1][data.value]">
                        {{ activo[0][data.value] }}
                      </b-badge>
                    </template>
                    <template #cell(accion)="row">
                      <!-- Column: Action -->
                      <div class="text-nowrap">
                        <feather-icon
                                v-b-tooltip.hover.top="'Editar Descuento'"
                                icon="Edit2Icon"
                                class="mr-1"
                                @click="editarDescuento(row.item)"
                                v-b-modal.modal-editar
                        />
                        <feather-icon
                                v-b-tooltip.hover.top="'Eliminar Descuento'"
                                icon="TrashIcon"
                                class="mr-1"
                                @click="confirmar(row.item,'eliminar')"
                        />

                        <feather-icon
                                :id="`status-${row.item.id}-send-acction`"
                                v-b-tooltip.hover.top="row.item.nameActionEstatus"
                                :icon="row.item.iconActionEstatus"
                                @click="confirmar(row.item)"
                        />
                      </div>
                    </template>
                    <template #empty="scope">
                      <div class="text-center">No existen resultados</div>
                    </template>
                  </b-table>
                </b-col>

              </b-row>
              <b-row class="pl-1">
                <b-col  cols="8" class="mt-3">
                  <div v-if="totalRows > 0">
                    <span class="text-muted">  Mostrando del {{from}} al {{to}} de {{totalRows}} filas</span>
                  </div>
                </b-col>

                <b-col cols="4">
                  <b-pagination
                          @change="onPageChange"
                          v-model="page"
                          :total-rows="totalRows"
                          :per-page="perPage"
                          align="right"
                          class="mt-3 mr-1"
                          aria-controls="my-table"
                  />
                </b-col>

              </b-row>
            </div>
          </div>

          <!-- Modal de Crear -->
          <b-modal
            id="modal-crear"
            title="Nuevo Descuento"
            ok-title="Guardar"
            cancel-variant="outline-primary"
            size="lg"
            @ok="validationFormCrear"
          >
            <validation-observer ref="simpleRules">
              <b-form name="formCrear">
                <b-row>

                  <!-- valor descuento -->
                  <b-col cols="6">
                    <b-form-group
                      label="Valor Descuento"
                      label-for="h-valor-descuento"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Valor Descuento"
                        rules="required|integer"
                      >
                        <b-form-input
                          id="h-valor-descuento"
                          v-model="valorDescuento"
                          placeholder="Valor Descuento $"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- tipo producto -->
                  <b-col cols="6">
                    <b-form-group
                      label="TipoProducto"
                      label-for="h-tipo-producto"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tipo Producto"
                        rules="required"
                      >
                        <autocomplete-infinity
                            url="descuentos/tipo-productos"
                            placeholder="Seleccione Tipo Producto"
                            @item-selected="selectTipoProductoCrear"
                            label="nombre"
                            id="tipoProductos"
                            :tipoProducto="tipoProducto"
                            itemsPerPage="10"
                            v-model="tipoProducto"
                        ></autocomplete-infinity>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- modalidad -->
                  <b-col cols="6">
                    <b-form-group
                      label="Modalidad"
                      label-for="h-modalidad"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tipo Modalidad"
                        rules="required"
                      >
                        <autocomplete-infinity
                            url="descuentos/modalidades"
                            placeholder="Seleccione Modalidad"
                            @item-selected="selectModalidadCrear"
                            label="name"
                            id="modalidad"
                            :modalidad="tipoModalidad"
                            itemsPerPage="10"
                            v-model="tipoModalidad"
                        ></autocomplete-infinity>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- tipo descuento -->
                  <b-col cols="6">
                    <b-form-group
                      label="TipoDescuento"
                      label-for="h-tipo-descuento"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tipo Descuento"
                        rules="required"
                      >
                        <autocomplete-infinity
                            url="descuentos/tipo-descuentos"
                            placeholder="Seleccione Tipo Descuento"
                            @item-selected="selectTipoDescuentosCrear"
                            label="nombre"
                            itemsPerPage="10"
                            id="tipoDescuento"
                            :tipoDescuento="tipoDescuento"
                            v-model="tipoDescuento"
                        ></autocomplete-infinity>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
          <!-- Fin Modal de Crear -->

          <!-- Modal de Editar -->
          <b-modal
            id="modal-editar"
            title="Editar Descuento"
            ok-title="Guardar"
            cancel-variant="outline-primary"
            size="lg"
            @ok="validationFormEditar"
          >
            <validation-observer ref="simpleRules">
              <b-form name="formCrear">
                <b-row>

                  <!-- valor descuento -->
                  <b-col cols="6">
                    <b-form-group
                      label="Valor Descuento"
                      label-for="h-valor-descuento"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Valor Descuento"
                        rules="required|integer"
                      >
                        <b-form-input
                          id="h-valor-descuento"
                          v-model="valorDescuentoEditar"
                          placeholder="Valor Descuento $"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- tipo producto -->
                  <b-col cols="6">
                    <b-form-group
                      label="TipoProducto"
                      label-for="h-tipo-producto"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tipo Producto"
                        rules="required"
                      >
                       <!-- <b-form-select
                          id="input-list"
                          v-model="tipoProductoEditar"
                          :options="optionsTipoProducto"
                          :state="errors.length > 0 ? false:null"
                        /> -->
                        <autocomplete-infinity
                            url="descuentos/tipo-productos"
                            placeholder="Tipo Producto"
                            @item-selected="selectTipoProductoEditar"
                            label="nombre"
                            id="tipoProductos"
                            :selected-value="tipoProductoEditar"
                            :tipoProducto="tipoProducto"
                            itemsPerPage="10"
                            v-model="tipoProductoEditar"
                        ></autocomplete-infinity>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- modalidad -->
                  <b-col cols="6">
                    <b-form-group
                      label="Modalidad"
                      label-for="h-modalidad"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tipo Modalidad"
                        rules="required"
                      >
                      <!--  <b-form-select
                          id="input-list"
                          v-model="tipoModalidadEditar"
                          :options="optionsModalidad"
                          :state="errors.length > 0 ? false:null"
                        />  -->
                        <autocomplete-infinity
                            url="descuentos/modalidades"
                            placeholder="Modalidad"
                            @item-selected="selectModalidadEditar"
                            label="name"
                            id="modalidad"
                            :modalidad="tipoModalidad"
                            itemsPerPage="10"
                            :selected-value="tipoModalidadEditar"
                            v-model="tipoModalidadEditar"
                        ></autocomplete-infinity>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- tipo descuento -->
                  <b-col cols="6">
                    <b-form-group
                      label="TipoDescuento"
                      label-for="h-tipo-descuento"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tipo Descuento"
                        rules="required"
                      >
                      <!--  <b-form-select
                          id="input-list"
                          v-model="tipoDescuentoEditar"
                          :options="optionsTipoDescuento"
                          :state="errors.length > 0 ? false:null"
                        /> -->
                        <autocomplete-infinity
                            url="descuentos/tipo-descuentos"
                            placeholder="Tipo Descuento"
                            @item-selected="selectTipoDescuentosEditar"
                            label="nombre"
                            itemsPerPage="10"
                            id="tipoDescuento"
                            :selected-value="tipoDescuentoEditar"
                            :tipoDescuento="tipoDescuento"
                            v-model="tipoDescuentoEditar"
                        ></autocomplete-infinity>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
          <!-- Fin Modal de Editar -->
        </b-card-body>
      </b-card>
    </b-overlay>

  </div>

</template>

<script>

import {
  BButton, BButtonToolbar, ButtonGroupPlugin, VBTooltip, BModal, VBModal, BForm, BFormInput, BFormGroup, BTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, integer } from '@validations'
import es from 'vee-validate/dist/locale/es.json'
import FiltrosDescuentos from '@/components/FiltrosDescuentos.vue'

export default {
  name: 'DescuentoProductos',
  components: {
    BButtonToolbar,
    ButtonGroupPlugin,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BTooltip,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    'filtros-descuentos': FiltrosDescuentos,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    'b-modal': VBModal,
  },
  props: {

  },
  data() {
    return {
      filter: {
        descuentoActivo: null,
        tipoProducto: null,
        tipoDescuento: null,
        modalidad: null,
      },
      required,
      integer,
      reportType: 1,
      idEditar: null,
      verTablaListado: null,
      descuentoEditar: [],
      valorDescuento: null,
      tipoDescuento: null,
      tipoProducto: null,
      tipoModalidad: null,
      valorDescuentoEditar: null,
      tipoDescuentoEditar: 1,
      tipoProductoEditar: null,
      tipoModalidadEditar: null,
      perPage: 50,
      pageOptions: [50, 100, 500, 1000],
      totalRows: 1,
      currentPage: 1,
      from: 1,
      to: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      page: 1,
      filterOn: [],
      count: 0,
      iconActionEstado: 'CircleIcon',
      nameActionEstado: 'Desactivar Descuento',
      fields: [
        { key: 'valor_descuento', label: 'Valor descuento'},
        { key: 'tipo_producto', label: 'Tipo Producto' },
        { key: 'modalidad', label: 'Modalidad' },
        { key: 'tipo_descuento', label: 'Tipo Descuento' },
        { key: 'activo', label: 'Status',  tdClass: 'text-center', thClass: 'text-center'},
        { key: 'accion', label: 'Accion', tdClass: 'text-center', thClass: 'text-center' },
      ],
      activo: [{
        0: 'No activo', 1: 'Activo',
      },
      {
        0: 'light-primary', 1: 'light-success',
      }],
      notificacion: '',
      descuentosList: [],
    }
  },
  computed: {
    // eslint-disable-next-line consistent-return,vue/return-in-computed-property
    items() {
      this.totalRows = this.$store.state.descuentoProductos.rowsTotal
      this.descuentosList = this.$store.state.descuentoProductos.descuentos.data
      this.currentPage = this.$store.state.descuentoProductos.currentPage
      this.to = this.$store.state.descuentoProductos.to
      this.from = this.$store.state.descuentoProductos.from
      if (this.descuentosList !== undefined) {
        return this.descuentosList.map(item => ({
          id: item.id,
          valor_descuento: item.valor_descuento,
          tipo_producto: item.tipo_producto,
          modalidad: item.modalidad,
          tipo_descuento: item.tipo_descuento,
          activo: item.activo,
          iconActionEstatus: item.activo == 1 ? 'CheckCircleIcon' : 'CircleIcon',
          nameActionEstatus: item.activo == 1 ? 'Desactivar Descuento' : 'Activar Descuento',
        }))
      }
    },
    notificaciones() {
      this.notificacion = this.$store.state.descuentoProductos.notificaciones
      if (this.notificacion.titulo !== '') {
        this.makeToast('success', this.notificacion.titulo, this.notificacion.mensaje)
        this.notificacion.titulo = ''
        this.getDescuentos()
      }
    },
    optionsTipoProducto() {
      console.log('optionTipo Product ok ')
      const productoSeleccionado = this.$store.state.descuentoProductos.descuentoEditar.tipo_producto
      const producto = JSON.parse(JSON.stringify(this.$store.state.descuentoProductos.selectTipoProducto))
      const lista = [{
        value: null,
        text: 'Debe seleccionar',
      }]
      // eslint-disable-next-line array-callback-return
      producto.map(tipo => {
        if (tipo.nombre == productoSeleccionado) {
          this.tipoProductoEditar = tipo.id.toString()
        }
        lista.push({
          value: tipo.id.toString(),
          text: tipo.nombre,
        })
      })
      return lista
    },
    optionsModalidad() {
      const modalidadSeleccionado = this.$store.state.descuentoProductos.descuentoEditar.modalidad
      const modalidad = JSON.parse(JSON.stringify(this.$store.state.descuentoProductos.selectModality))
      const lista = [{
        value: null,
        text: 'Debe seleccionar',
      }]
      modalidad.map(tipo => {
        if (tipo.name == modalidadSeleccionado) {
          this.tipoModalidadEditar = tipo.id.toString()
        }
        lista.push({
          value: tipo.id.toString(),
          text: tipo.name,
        })
      })
      return lista
    },
    optionsTipoDescuento() {
      const descuentoSeleccionado = this.$store.state.descuentoProductos.descuentoEditar.tipo_descuento
      const descuento = JSON.parse(JSON.stringify(this.$store.state.descuentoProductos.selectTipoDescuento))
      const lista = [{
        value: null,
        text: 'Debe seleccionar',
      }]
      descuento.map(tipo => {
        if (tipo.nombre == descuentoSeleccionado) {
          this.tipoDescuentoEditar = tipo.id.toString()
        }
        lista.push({
          value: tipo.id.toString(),
          text: tipo.nombre,
        })
      })
      return lista
    },
    // eslint-disable-next-line vue/no-dupe-keys
    loading() {
      return this.$store.state.descuentoProductos.loading
    },
  },
  mounted() {
    //por Vero
    this.getDescuentos()
    localize('es', es)
  },
  methods: {
    buscar(filter) {
      this.filter = filter
      this.page = 1
      this.getDescuentos()
    },
    selectTipoProductoCrear(tipoProductos) {
      this.tipoProducto = tipoProductos != null ? tipoProductos.id : null
    },
    selectModalidadCrear(modalidades) {
      this.tipoModalidad = modalidades != null ? modalidades.id : null
    },
    selectTipoDescuentosCrear(tipoDescuentos) {
      this.tipoDescuento = tipoDescuentos != null ? tipoDescuentos.id : null
    },
    selectTipoProductoEditar(tipoProductosEditar) {
      console.log('tipoProductosEditar',tipoProductosEditar)
      this.tipoProductoEditar = tipoProductosEditar != null ? tipoProductosEditar : null
    },
    selectModalidadEditar(modalidades) {
      this.tipoModalidadEditar = modalidades != null ? modalidades : null
    },
    selectTipoDescuentosEditar(tipoDescuentos) {
      this.tipoDescuentoEditar = tipoDescuentos != null ? tipoDescuentos : null
    },
    getDescuentos() {
      const filterData = {
        page: this.page,
        perPage: this.perPage,
        descuentoActivo: this.filter.descuentoActivo,
        tipoProducto: this.filter.tipoProducto,
        tipoDescuento: this.filter.tipoDescuento,
        modalidad: this.filter.modalidad,
      }
      this.verTablaListado = true
      this.$store.dispatch('descuentoProductos/getDescuentos', filterData)
    },
    validationFormCrear(objeto) {
      console.log(objeto)
      objeto.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          //alert('form submitted!')
          this.agregarDescuento()
          this.$bvModal.hide('modal-crear')
        }
      })
    },
    validationFormEditar(objeto) {
      objeto.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          //alert('form submitted!')
          this.editarDescuentoConfirmado()
          this.$bvModal.hide('modal-editar')
        }
      })
    },
    limpiarFormCrear() {
      this.valorDescuento = null
      this.tipoDescuento = null
      this.tipoProducto = null
      this.tipoModalidad = null
      this.idEditar = null
      // You should call it on the next frame
      requestAnimationFrame(() => {
        this.$refs.simpleRules.reset()
      })
    },
    agregarDescuento() {
      this.datosCrear = {
        id: null,
        valorDescuento: this.valorDescuento,
        tipoDescuento: this.tipoDescuento,
        tipoProducto: this.tipoProducto,
        tipoModalidad: this.tipoModalidad,
      }
      this.$store.dispatch('descuentoProductos/agregarEditarDescuento', this.datosCrear)
      this.limpiarFormCrear()
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onPageChange(page) {
      this.page = page
      this.getDescuentos()
    },
    onPerPageChange(perPage) {
      this.perPage = perPage
      this.getDescuentos()
    },
    editarDescuento(item) {
      this.$store.commit('descuentoProductos/setDescuentoEditar', item)
      // this.$router.push('/descuento-productos/editar')
      this.$store.dispatch('descuentoProductos/getSelectDescuentos')
      this.valorDescuentoEditar = this.$store.state.descuentoProductos.descuentoEditar.valor_descuento
      this.idEditar = this.$store.state.descuentoProductos.descuentoEditar.id
      this.tipoDescuentoEditar = item.tipo_descuento
      this.tipoProductoEditar = item.tipo_producto
      this.tipoModalidadEditar = item.modalidad
      console.log('this.tipoDescuentoEditar',item.tipo_descuento)
    },
    editarDescuentoConfirmado() {
      this.datosEditar = {
        id: this.idEditar,
        valorDescuento: this.valorDescuentoEditar,
        tipoDescuento: this.tipoDescuentoEditar.id,
        tipoProducto: this.tipoProductoEditar.id,
        tipoModalidad: this.tipoModalidadEditar.id,
      }
      this.$store.dispatch('descuentoProductos/agregarEditarDescuento', this.datosEditar)
    },
    eliminarDescuento(id) {
      this.$store.dispatch('descuentoProductos/eliminarDescuento', id)
    },
    desactivarActivarDescuento(id) {
      this.$store.dispatch('descuentoProductos/activarDesactivarDescuento', id)
    },
    makeToast(variant = null, titulo, mensaje) {
      this.$bvToast.toast(mensaje, {
        // title: `Variant ${variant || 'default'}`,
        title: titulo,
        variant,
        solid: true,
      })
    },
    confirmar(item, accion) {
      if (accion == null){
        accion = item.activo == 1 ? 'Desactivar' : 'Activar'
      }
      this.$bvModal
        .msgBoxConfirm(`¿Usted está seguro que desea ${accion} el descuento?`, {
          title: 'Por favor Confirmar',
          size: 'm',
          okVariant: 'primary',
          okTitle: 'Si',
          cancelTitle: 'No',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            if (accion === 'eliminar') {
              this.eliminarDescuento(item.id)
            } else {
              this.desactivarActivarDescuento(item.id)
            }
          }
        })
    },
  },
}
</script>

<style>


</style>
