<template>
    <b-card>
        <b-form @submit.prevent>
            <app-collapse>
                <app-collapse-item title="Búsqueda">
                    <b-row>
                        <b-col md="12">
                            <b-form-group

                            >
                                <h5 class="font-weight-bold">
                                    Descuentos
                                </h5>
                                <div class="demo-inline-spacing descuento">

                                    <b-form-radio
                                            v-model="filter.descuentoActivo"
                                            name="some-radio9"
                                            value="null"
                                            class="custom-control-primary"
                                    >
                                        Todos
                                    </b-form-radio>
                                    <b-form-radio
                                            v-model="filter.descuentoActivo"
                                            name="some-radio9"
                                            value="1"
                                            class="custom-control-primary"
                                    >
                                        Activos
                                    </b-form-radio>

                                  <b-form-radio
                                      v-model="filter.descuentoActivo"
                                      name="some-radio9"
                                      value="0"
                                      class="custom-control-primary"
                                  >
                                    No Activos
                                  </b-form-radio>
                                </div>

                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group
                            >
                                <h5 class="font-weight-bold">
                                    Tipo Producto
                                </h5>
                                <div class="form-label-group">
                                    <autocomplete-infinity
                                            url="descuentos/tipo-productos"
                                            placeholder="Seleccione Tipo Producto"
                                            @item-selected="selectTipoProducto"
                                            label="nombre"
                                            id="tipoProductos"
                                            :tipoProducto="filter.tipoProducto"
                                            itemsPerPage="10"
                                    ></autocomplete-infinity>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group
                            >
                                <h5 class="font-weight-bold">
                                    Modalidad
                                </h5>
                                <div class="form-label-group">
                                    <autocomplete-infinity
                                            url="descuentos/modalidades"
                                            placeholder="Seleccione Modalidad"
                                            @item-selected="selectModalidad"
                                            label="name"
                                            id="modalidad"
                                            :modalidad="filter.modalidad"
                                            itemsPerPage="10"
                                    ></autocomplete-infinity>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group
                            >
                                <h5 class="font-weight-bold">
                                    Tipo Descuento
                                </h5>
                                <div class="form-label-group">
                                    <b-form-group>
                                        <autocomplete-infinity
                                                url="descuentos/tipo-descuentos"
                                                placeholder="Seleccione Tipo Descuento"
                                                @item-selected="selectTipoDescuentos"
                                                label="nombre"
                                                itemsPerPage="10"
                                                id="tipoDescuento"
                                                :tipoDescuento="filter.tipoDescuento"
                                        ></autocomplete-infinity>

                                    </b-form-group>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">

                        </b-col>
                        <b-col>
                            <div class="text-right">
                                <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        type="submit"
                                        v-on:click="$emit('buscar', filter)">

                                    <feather-icon
                                            icon="SearchIcon"
                                            class="mr-50"
                                    />
                                    <span class="align-middle">Buscar</span>
                                </b-button>
                                <b-button class="d-none"
                                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                          variant="outline-primary"
                                          type="submit">

                                    <feather-icon
                                            icon="RefreshCcwIcon"
                                            class="mr-50"
                                    />
                                    <span class="align-middle">Limpiar</span>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </app-collapse-item>
            </app-collapse>
        </b-form>
    </b-card>
</template>


<script>
    import {
        BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormRadio, BFormDatepicker
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import flatPickr from 'vue-flatpickr-component'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import vSelect from 'vue-select'

    export default {
        name: "FiltrosReportes",
        components: {
            AppCollapse,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BForm,
            BButton,
            BFormRadio,
            flatPickr,
            AppCollapseItem,
            BFormDatepicker,
            vSelect,

        },
        directives: {
            Ripple,
        },
        props: ['reportType'],
        data() {
            return {
                filter: {
                    descuentoActivo: null,
                    tipoProducto: null,
                    tipoDescuento: null,
                    modalidad: null,
                },
            }
        },
        methods: {
            selectTipoProducto(tipoProductos) {
                this.filter.tipoProducto = tipoProductos != null ? tipoProductos.id : null;
                console.log('filtro ', tipoProductos)
            },
            selectModalidad(modalidades) {
                this.filter.modalidad = modalidades != null ? modalidades.id : null;
              console.log('filtro ', this.filter.modalidad)
            },
          selectTipoDescuentos(tipoDescuentos) {
                this.filter.tipoDescuento = tipoDescuentos != null ? tipoDescuentos.id : null;
            console.log('filtro ', this.filter.tipoDescuento)
            },

        },
        computed: {
            config() {
                return {
                    locale: require(`flatpickr/dist/l10n/es.js`).default['es'],
                    dateFormat: "d-m-Y",
                    wrap: true,
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

    .demo-inline-spacing.curso .custom-radio {
        margin-top: 0.5rem !important;
    }

    .btn-clear {
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d8d6de;
        border-radius: 0px 3px 3px 0px!important;
        color: #6e6b7b;
    }
</style>
